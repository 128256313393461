module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#276439","theme_color":"#276439","display":"minimal-ui","icon":"src/images/ajzk_logo.png","icons":[{"src":"favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"favicon/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicon/favicon-128.png","sizes":"128x128","type":"image/png"},{"src":"favicon/favicon-196x196.png","sizes":"196x196","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"20fae18c46adc86f173abaf47bbd0bc7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156459067-1","respectDNT":true,"pageTransitionDelay":0,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"ajzk.pl","head":false,"anonymize":false,"exclude":[],"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
